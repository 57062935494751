import React, { useContext, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Box } from "../components/Core"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"
import { slugHome, slugDisclaimer } from "../utils/slugs"

import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"

import bgImg from "../assets/images/bg5.jpg"

const Disclaimer = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.default) {
      gContext.setCurrentLang(allLangs.default)
    }
  }, [])

  const pData = data.prismicDisclaimerPage?.data
  const { btn_home } = data.prismicCommon?.data
  return (
    <>
      <GatsbySeo
        title={pData.meta_title?.text}
        description={pData.meta_description?.text}
      />

      <div>
        <PageWrapper>
          <Hero bgImg={pData?.cover_image.url || bgImg}>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <Title variant="hero" color="light">
                    {pData?.title ? pData?.title.text : Disclaimer}
                  </Title>

                  <Box mt={4}>
                    <div className="d-flex my-3 justify-content-center">
                      <div className="text-white font-weight-bold">
                        {">>"}
                        <Link
                          to={slugHome(gContext.currentLang)}
                          className="text-white font-weight-bold"
                        >
                          {btn_home ? btn_home.text : "Home"}
                        </Link>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Container>
          </Hero>

          <Section
            pt={["45px !important", null, null, "60px !important"]}
            className="pb-5"
          >
            <Container fluid>
              <Row className="justify-content-center">
                <Col lg="9" className="px-md-5">
                  <Row>
                    <Col md={12}>
                      <Box mb="40px">
                        <div className="d-flex my-3 justify-content-left">
                          <div className="font-weight-bold">
                            {">>"}
                            <Link
                              to={slugHome(gContext.currentLang)}
                              className="font-weight-bold breadcrumb-link "
                            >
                              {btn_home ? btn_home.text : "Home"}
                            </Link>
                          </div>
                          <div className="font-weight-bold ml-2">
                            {">>"}
                            <Link
                              to={slugDisclaimer(gContext.currentLang)}
                              className="font-weight-bold breadcrumb-link "
                            >
                              {pData?.title ? pData?.title.text : "Disclaimer"}
                            </Link>
                          </div>
                        </div>
                      </Box>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pData?.content.html,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="3">
                  <ProductAccordion />
                  <ProductSlider />
                </Col>
              </Row>
            </Container>
          </Section>

          <div id="support">
            <Support />
          </div>
          <div id="contact">
            <Contact />
          </div>
        </PageWrapper>
      </div>
    </>
  )
}
export default Disclaimer

export const query = graphql`
  query($lang: String!) {
    prismicDisclaimerPage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        cover_image {
          url
        }
        content {
          html
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }

    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_home {
          text
        }
      }
    }
  }
`
